import React, { Component } from 'reactn'
import Api from '../Services/Api'
import './Styles/Notifications.css'
import WithRouter from './Components/WithRouter'
import { Button, FormControl, InputGroup } from 'react-bootstrap'
import Modal from 'react-modal'
import swal from 'sweetalert'

const DEF_OBJ = {
  title: '',
  body: '',
  groupName: '',
  selectedGroupName: '...',
  groupId: null,
  usersGroup: [],
  groups: [],
  newGroup: false,
  sendToGroupId: null,
  all: null,
  codFornitore: '...'
}

class Notifications extends Component {
  constructor (props) {
    super(props)
    this.state = {
      users: [],
      notifications: [],
      ...DEF_OBJ
    }
  }

  async componentDidMount () {
    const res = await Api.get('/users')
    if (res.ok) {
      this.setState({ users: res.data })
      await this.fetchNotifications()
      await this.fetchGroups()
    } else {
      swal('Impossibile recuperare gli utenti')
    }
  }

  async fetchNotifications () {
    const notificationsResponse = await Api.get('/notifications')
    if (notificationsResponse.ok) {
      this.setState({ notifications: notificationsResponse.data.reverse() })
    } else {
      swal('Impossibile recuperare le notifiche')
    }
  }

  async fetchGroups () {
    const ret = await Api.get('/groups')
    this.setState({ groups: ret.data })
  }

  async sendPushNotification () {
    const { codFornitore, title, body, all, sendToGroupId } = this.state

    if (title === '' || body === '' || (!all && !sendToGroupId && codFornitore === '...')) {
      swal('Verifica i dati immessi prima di inviare la notifica push')
      return
    }

    const currentDate = new Date()

    const dd = String(currentDate.getDate()).padStart(2, '0')
    const mm = String(currentDate.getMonth() + 1).padStart(2, '0') // January is 0!
    const yyyy = currentDate.getFullYear()
    const HH = String(currentDate.getHours()).padStart(2, '0')
    const MM = String(currentDate.getMinutes()).padStart(2, '0')
    const SS = String(currentDate.getSeconds()).padStart(2, '0')

    const date = `${dd}/${mm}/${yyyy} ${HH}:${MM}:${SS}`

    const res = await Api.post('/notifications', { codFornitore, title, body, date, all, sendToGroupId })
    if (res.ok) {
      swal('Push notification inviata con successo')
      this.setState(DEF_OBJ)
      await this.fetchNotifications()
      await this.fetchGroups()
    } else {
      swal('Impossibile inviare push notification')
    }
  }

  async deleteNotification (_id) {
    const res = await Api.delete(`/notifications/${_id}`)
    if (res.ok) {
      swal('Push notification eliminata con successo')
      await this.fetchNotifications()
    } else {
      swal('Impossibile eliminare push notification')
    }
  }

  deleteUserGroup (codFornitore) {
    const { usersGroup } = this.state
    const update = usersGroup.filter(s => s.codFornitore !== codFornitore)
    this.setState({ usersGroup: update })
  }

  async saveNewGroup () {
    const { groupName, usersGroup } = this.state
    const res = await Api.post('/groups', { groupName, usersGroup })
    if (res.ok) {
      swal('Gruppo creato con successo')
      this.setState(DEF_OBJ)
      await this.fetchGroups()
    } else {
      swal('Impossibile creare il gruppo')
    }
  }

  async updateNewGroup () {
    const { groupId, usersGroup } = this.state
    const res = await Api.patch(`/groups/${groupId}`, { usersGroup })
    if (res.ok) {
      swal('Gruppo aggiornato con successo')
      this.setState(DEF_OBJ)
      await this.fetchGroups()
    } else {
      swal('Impossibile aggiornare il gruppo')
    }
  }

  async deleteNewGroup () {
    const { groupId } = this.state
    const res = await Api.delete(`/groups/${groupId}`)
    if (res.ok) {
      swal('Gruppo eliminato con successo')
      this.setState(DEF_OBJ)
      await this.fetchGroups()
    } else {
      swal('Impossibile aggiornare il gruppo')
    }
  }

  render () {
    const {
      users, notifications, title, body, newGroup,
      groupName, usersGroup, groups, all, selectedGroupName,
      sendToGroupId, codFornitore, groupId
    } = this.state

    return (
      <div className='notif-page'>
        <div className='notif-back'>
          <button className='button' onClick={() => this.props.navigate('/')}>
            <i className='fas fa-arrow-left' />
          </button>
          <button className='button' onClick={() => this.setState({ newGroup: true })}>
            <i className='fas fa-users' />
          </button>
        </div>

        <div className='container'>
          <input value={title} type='text' placeholder='Titolo del messaggio' onChange={e => this.setState({ title: e.target.value })} />
          <textarea value={body} placeholder='Messaggio' rows={10} onChange={e => e.target.value.length <= 1200 && this.setState({ body: e.target.value })} />
          <p>{this.state.body.length}/1200</p>
          <div className='checkbox-section'>
            <p>Invia a tutti i soci:</p>
            <label className='custom-checkbox'>
              <input
                type='checkbox'
                onChange={e => this.setState({ all: e.target.checked })}
                disabled={!!sendToGroupId || codFornitore}
              />
              <span className='checkmark' />
            </label>
          </div>

          <p>oppure seleziona un gruppo:</p>
          <select
            style={{ backgroundColor: groups.length === 0 || all || codFornitore ? 'lightgray' : 'white', marginBottom: 20 }}
            disabled={groups.length === 0 || all || codFornitore}
            onChange={e => {
              if (e.target.value !== '...') {
                const item = e.target.value.split('|')
                const sendToGroupId = item[0]
                this.setState({ sendToGroupId })
              } else {
                this.setState({ sendToGroupId: null })
              }
            }}
          >
            <option>...</option>
            {
              groups && groups.length > 0 && groups.map((group, i) => {
                return (
                  <option key={i} value={`${group._id}|${group.groupName}`}>{group.groupName}</option>
                )
              })
            }
          </select>

          <p>oppure seleziona il singolo socio:</p>
          <select
            value={codFornitore}
            style={{ backgroundColor: users.length === 0 || all || !!sendToGroupId ? 'lightgray' : 'white' }}
            disabled={users.length === 0 || all || !!sendToGroupId}
            onChange={e => {
              if (e.target.value !== '...') {
                this.setState({ codFornitore: e.target.value })
              } else {
                this.setState({ codFornitore: null })
              }
            }}
          >
            <option value='...'>...</option>
            {
              users && users.length > 0 && users.map((user, i) => {
                return (
                  <option key={i} value={user.email}>
                    {user.email} - {user.name}
                  </option>
                )
              })
            }
          </select>
          <Button variant='success' style={{ width: 250, marginTop: 20 }} onClick={() => this.sendPushNotification()}>Invia Notifica Push</Button>
        </div>
        {/* Display notifications in a table */}
        <div className='table-container'>
          <h5>Notifications</h5>
          <table>
            <thead>
              <tr>
                <th style={{ width: '15%' }}>Codice Fornitore</th>
                <th>Titolo</th>
                <th>Messaggio</th>
                <th style={{ width: '15%' }}>Data Invio</th>
                <th>A tutti</th>
                <th style={{ width: '15%' }}>A un gruppo</th>
                <th>Elimina</th>
              </tr>
            </thead>
            <tbody>
              {notifications && notifications.length > 0 && notifications.map((notification, i) => (
                <tr key={i}>
                  <td>{notification.codFornitore}</td>
                  <td>{notification.title}</td>
                  <td>{notification.body}</td>
                  <td>{notification.date}</td>
                  <td><input type='checkbox' checked={notification.all} disabled /></td>
                  <td>{notification.sendToGroupId ? groups.filter(g => g._id === notification.sendToGroupId)[0]?.groupName : ''}</td>
                  <td><Button variant='danger' onClick={() => this.deleteNotification(notification._id)}>Elimina</Button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Modal
          isOpen={newGroup}
          style={customStyle}
          ariaHideApp={false}
          className='newUserModal'
          onRequestClose={() => this.setState({ newGroup: !newGroup })}
        >
          <div style={{ backgroundColor: 'white', minWidth: 400, padding: 20, borderRadius: 20 }}>
            <h5 style={{ fontSize: 18, fontWeight: 'bold' }}>Registrazione Nuovo Gruppo</h5>

            <p>Nome del gruppo</p>
            <InputGroup className='mb-3'>
              <FormControl value={groupName} onChange={(e) => this.setState({ groupName: e.target.value })} />
            </InputGroup>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
              <p>oppure seleziona un gruppo esistente</p>
              {groupId && <Button variant='danger' onClick={() => this.deleteNewGroup()} style={{ marginLeft: 15 }}>Elimina gruppo</Button>}
            </div>
            <select
              style={{ backgroundColor: groups.length === 0 ? 'lightgray' : 'white', marginBottom: 20 }}
              disabled={groups.length === 0}
              onChange={e => {
                if (e.target.value !== '...') {
                  const item = e.target.value.split('|')
                  const groupId = item[0]
                  const groupName = item[1]
                  const usersGroup = groups.filter(group => group.groupName === groupName)[0]?.usersGroup
                  this.setState({ selectedGroupName: groupName, groupId, usersGroup })
                }
              }}
            >
              <option>...</option>
              {
                groups && groups.length > 0 && groups.map((group, i) => {
                  return (
                    <option key={i} value={`${group._id}|${group.groupName}`}>{group.groupName}</option>
                  )
                })
              }
            </select>

            <p>Aggiungi un socio selezionandolo</p>
            <select
              style={{ backgroundColor: users.length === 0 ? 'lightgray' : 'white', marginBottom: 20 }}
              disabled={users.length === 0}
              onChange={e => {
                if (e.target.value !== '...') {
                  const item = e.target.value.split('|')
                  const update = [...usersGroup, { codFornitore: item[0], fornitore: item[1] }]
                  const unique = Array.from(new Set(update.map(JSON.stringify))).map(JSON.parse)
                  this.setState({ usersGroup: unique })
                }
              }}
            >
              <option>...</option>
              {
                users && users.length > 0 && users.map((user, i) => {
                  return (
                    <option key={i} value={`${user.email}|${user.name}`}>{user.email} - {user.name}</option>
                  )
                })
              }
            </select>

            <div style={{ maxHeight: 350, overflow: 'scroll' }}>
              <table style={{ marginBottom: 20, overflow: 'scroll' }}>
                <thead>
                  <tr>
                    <th style={{ width: '20%' }}>Codice Fornitore</th>
                    <th style={{ width: '75%' }}>Ragione Sociale</th>
                    <th style={{ width: '5%' }} />
                  </tr>
                </thead>
                <tbody>
                  {
                    usersGroup && usersGroup.length > 0 && usersGroup.map((s, i) => {
                      return (
                        <tr key={i}>
                          <td>{s.codFornitore}</td>
                          <td>{s.fornitore}</td>
                          <td><Button variant='danger' onClick={() => this.deleteUserGroup(s.codFornitore)}>Elimina</Button></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              {selectedGroupName === '...' && <Button variant='success' onClick={() => this.saveNewGroup()} style={{ marginLeft: 15 }}>Crea gruppo</Button>}
              {selectedGroupName !== '...' && <Button variant='info' onClick={() => this.updateNewGroup()} style={{ marginLeft: 15 }}>Aggiorna gruppo</Button>}
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

const customStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    position: 'absolute',
    zIndex: 999999999999999,
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: 20,
    maxWidth: 600
  },
  outline: 'none'
}

export default WithRouter(Notifications)
