import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { jsPDF as JSPDF } from 'jspdf'
import md5 from 'md5'
import swal from 'sweetalert'
import Api from '../Services/Api'

export default class TableDetail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: null,
      soci: [],
      selectedSoci: []
    }
  }

  async componentDidMount () {
    const user = this.props.data
    this.setState({ user })

    await this.getSociFornitori(user)
  }

  async downloadPDF (code) {
    const canvas = document.getElementById('qr-code')
    const img = canvas.toDataURL('image/jpg', 1.0)
    const pdf = new JSPDF({
      orientation: 'l',
      format: 'credit-card'
    })
    pdf.addImage(img, 'JPG', 2, 2)
    pdf.setFontSize(8).setFont(undefined, 'bold')
    pdf.text(code, 30, 15)
    pdf.save(`${code}.pdf`)

    this.props.refresh()
  }

  async passwordReset () {
    const { user } = this.state
    const hash = md5((user.email).toString())
    const password = hash.substring(hash.length - 8, hash.length)

    const res = await Api.patch(`/users/${user._id}`, {
      password,
      firstLogin: true
    })
    if (res.ok) {
      swal('Password reset effettuato con successo!')
      this.props.loadData()
    } else {
      swal('Impossibile effettuare il password reset.')
    }
  }

  async getSociFornitori (user) {
    const ret = await Api.get(`/users/${user._id}`)

    const soci = this.props.soci
    const userSoci = ret.data.soci || []
    const selectedSoci = soci.filter(s => userSoci.includes(s.codFornitore))

    this.setState({ soci, selectedSoci })
  }

  async saveSelectedSoci () {
    const { user, selectedSoci } = this.state
    const soci = selectedSoci.map(s => Number(s.codFornitore))

    const res = await Api.patch(`/users/${user._id}`, { soci })
    if (res.ok) {
      swal('Visualizzazione soci aggiornata correttamente')
      this.props.loadData()
    } else {
      swal('Impossibile aggiungere i soci, riprovare piu tardi')
    }
  }

  deleteSelectedSocio (codFornitore) {
    const { selectedSoci } = this.state
    const update = selectedSoci.filter(s => s.codFornitore !== codFornitore)
    this.setState({ selectedSoci: update })
  }

  render () {
    const { container, save, box } = styles
    const { user, soci, selectedSoci } = this.state

    return (
      <div style={container}>
        <div style={box}>
          <h5>Selezionare soci specifici da visualizzare nell'app:</h5>
          <select
            style={{ backgroundColor: soci.length === 0 ? 'lightgray' : 'white' }}
            disabled={soci.length === 0}
            onChange={e => {
              const item = e.target.value.split('|')
              this.setState({ selectedSoci: [...selectedSoci, { codFornitore: item[0], fornitore: item[1] }] })
            }}
          >
            <option>...</option>
            {
              soci && soci.length > 0 && soci.map((socio, i) => {
                return (
                  <option key={i} value={`${socio.codFornitore}|${socio.fornitore}`}>{socio.codFornitore} - {socio.fornitore}</option>
                )
              })
            }
          </select>
          <div className='table-container2'>
            <h5>Soci selezionati:</h5>
            <table>
              <thead>
                <tr>
                  <th style={{ width: '20%' }}>Codice Fornitore</th>
                  <th style={{ width: '75%' }}>Ragione Sociale</th>
                  <th style={{ width: '5%' }} />
                </tr>
              </thead>
              <tbody>
                {
                  selectedSoci && selectedSoci.length > 0 && selectedSoci.map((s, i) => {
                    return (
                      <tr key={i}>
                        <td>{s.codFornitore}</td>
                        <td>{s.fornitore}</td>
                        <td><Button style={save} variant='danger' onClick={() => this.deleteSelectedSocio(s.codFornitore)}>Elimina</Button></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
          <Button style={save} variant='warning' onClick={() => this.saveSelectedSoci()}>Salva soci</Button>
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {user && user.type === 'user' && <Button style={save} variant='warning' onClick={() => this.passwordReset()}>Password reset</Button>}
          {/* {user && user.type === 'user' && <Button style={save} variant='danger' onClick={() => this.deleteUser()}>Elimina Utente</Button>} */}
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    width: '100%'
  },
  save: {
    width: 150,
    height: 40,
    margin: 8
  },
  box: {
    width: '50%'
  }
}
