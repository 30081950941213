import React, { Component } from 'reactn'
import { Button } from 'react-bootstrap'
import WithRouter from './Components/WithRouter'
import Background from '../Components/Background'
import logo from '../Assets/logo.png'
import './Styles/Home.css'
import { persistState, clearState } from '../Services'

class Home extends Component {
  async logout () {
    await this.setGlobal({ loggedIn: false }, persistState)
    clearState()
    window.location.reload()
  }

  render () {
    const { logoBox } = styles

    return (
      <Background>
        <div style={logoBox}>
          <img src={logo} style={{ width: 330, height: 80 }} alt='' />
        </div>
        <div className='home-page'>
          <button className='button btn-home' onClick={() => this.props.navigate('users')}>
            <i className='fas fa-user' />
            <span>Users</span>
          </button>
          <button className='button btn-home' onClick={() => this.props.navigate('notifications')}>
            <i className='fas fa-bell' />
            <span>Notifiche</span>
          </button>
          <button className='button btn-home' onClick={() => this.props.navigate('automations')}>
            <i className='fas fa-bell' />
            <span>Automazioni</span>
          </button>
        </div>
        <Button variant='danger' style={{ position: 'absolute', width: 100, height: 40, top: 20, right: 20 }} onClick={() => this.logout()}>Logout</Button>
      </Background>
    )
  }
}

const styles = {
  logoBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // ...Config.shadow,
    marginBottom: 20,
    borderRadius: 100,
    padding: 20,
    position: 'fixed',
    top: 10,
    left: 10
  }
}

export default WithRouter(Home)
