import React, { Component } from 'reactn'
import { Card, InputGroup, FormControl, Button } from 'react-bootstrap'
import swal from 'sweetalert'
import Config from '../Global/Config'
import { persistState, Api } from '../Services'
import logo from '../Assets/logo.png'

export default class Login extends Component {
  async login () {
    const { user, psw } = this.state

    if (user === '' || psw === '') {
      swal('Error', 'Completare tutti i campi, poi premere login', 'error')
    } else {
      const data = await Api.login(user, psw)

      if (!data) {
        swal('Error', 'Le credenziali immesse sono errate', 'error')
      } else {
        await this.setGlobal({ ...data, loggedIn: true }, persistState)
        this.props.postLogin()
      }
    }
  }

  async handleKeyDown (event) {
    if (event.keyCode === 13) {
      await this.login()
    }
  }

  render () {
    const { page, logoBox, footer, a } = styles

    return (
      <div style={page}>
        <div style={logoBox}>
          <img src={logo} style={{ width: 330, height: 80 }} alt='' />
        </div>
        <Card style={{ width: 360, ...Config.shadow, padding: 20, borderRadius: 10 }}>
          <span style={{ marginBottom: 10, fontSize: 18, fontWeight: 'bold' }}>Area riservata</span>
          <InputGroup className='mb-3'>
            <InputGroup.Text>Username</InputGroup.Text>
            <FormControl onChange={(e) => this.setState({ user: e.target.value })} />
          </InputGroup>
          <InputGroup className='mb-3'>
            <InputGroup.Text>Password</InputGroup.Text>
            <FormControl type='password' onChange={(e) => this.setState({ psw: e.target.value })} onKeyDown={e => this.handleKeyDown(e)} />
          </InputGroup>
          <Button style={{ width: 120, margin: 'auto' }} size='lg' variant='success' onClick={() => this.login()}>LOGIN</Button>
        </Card>
        <div style={footer}>
          © 2023 Joinfruit | P.IVA 03614040040 | EMail <a href='mailto:info@joinfruit.com' style={a}>info@joinfruit.com</a> | v {Config.appVersion}
        </div>
      </div>
    )
  }
}

const styles = {
  page: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logoBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // ...Config.shadow,
    marginBottom: 20,
    borderRadius: 100,
    padding: 20,
    position: 'fixed',
    top: 10,
    left: 10
  },
  footer: {
    position: 'fixed',
    bottom: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    color: 'white'
  },
  a: {
    color: 'white',
    textDecoration: 'underline',
    marginLeft: 6,
    marginRight: 6
  }
}
