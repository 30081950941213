import React, { Component } from 'react'
import WithRouter from './Components/WithRouter'
import { Button } from 'react-bootstrap'
import Api from '../Services/Api'
import swal from 'sweetalert'

class Automations extends Component {
  constructor (props) {
    super(props)

    this.state = {
      primaSingolaLimit: 0,
      primaGruppoLimit: 0,
      terzaSingolaLimit: 0,
      terzaGruppoLimit: 0,
      enabledPrima: false,
      enabledTerza: false,
      primaSingola: false,
      primaGruppo: false,
      terzaSingola: false,
      terzaGruppo: false
    }
  }

  async componentDidMount () {
    await this.fetchData()
  }

  async fetchData () {
    const res = await Api.get('/automations/calibrature')
    if (res.ok) {
      const {
        primaSingolaLimit, primaGruppoLimit, terzaSingolaLimit, terzaGruppoLimit,
        enabledPrima, enabledTerza, primaSingola, primaGruppo, terzaSingola, terzaGruppo
      } = res.data
      this.setState({
        primaSingolaLimit,
        primaGruppoLimit,
        terzaSingolaLimit,
        terzaGruppoLimit,
        enabledPrima,
        enabledTerza,
        primaSingola,
        primaGruppo,
        terzaSingola,
        terzaGruppo
      })
    }
  }

  async saveAutomations () {
    const {
      primaSingolaLimit, primaGruppoLimit, terzaSingolaLimit, terzaGruppoLimit,
      enabledPrima, enabledTerza, primaSingola, primaGruppo, terzaSingola, terzaGruppo
    } = this.state

    const res = await Api.patch('/automations/calibrature', {
      primaSingolaLimit,
      primaGruppoLimit,
      terzaSingolaLimit,
      terzaGruppoLimit,
      enabledPrima,
      enabledTerza,
      primaSingola,
      primaGruppo,
      terzaSingola,
      terzaGruppo
    })
    if (res.ok) {
      swal('Le automazioni sono state aggiornate con successo')
    } else {
      swal('Impossibile aggiornare le automazioni')
    }
  }

  render () {
    const {
      primaSingolaLimit, primaGruppoLimit, terzaSingolaLimit, terzaGruppoLimit,
      enabledPrima, enabledTerza, primaSingola, primaGruppo, terzaSingola, terzaGruppo
    } = this.state

    return (
      <div className='notif-page'>
        <div className='notif-back'>
          <button className='button' onClick={() => this.props.navigate('/')}>
            <i className='fas fa-arrow-left' />
          </button>
        </div>

        <div style={{ marginLeft: 150 }} className='table-container'>
          <h4>Automazioni</h4>

          <div className='checkbox-section'>
            <p>Abilita notifiche per la qualità I:</p>
            <label className='custom-checkbox'>
              <input
                type='checkbox'
                checked={enabledPrima}
                onChange={e => this.setState({ enabledPrima: e.target.checked })}
              />
              <span className='checkmark' />
            </label>
          </div>

          <div className='checkbox-section'>
            <p>Abilita notifiche per la qualità I <strong>singola</strong>:</p>
            <label className='custom-checkbox'>
              <input
                type='checkbox'
                checked={primaSingola}
                onChange={e => this.setState({ primaSingola: e.target.checked })}
              />
              <span className='checkmark' />
            </label>
          </div>
          <div className='checkbox-section'>
            <p>Abilita notifiche per la qualità I <strong>raggruppata</strong>:</p>
            <label className='custom-checkbox'>
              <input
                type='checkbox'
                checked={primaGruppo}
                onChange={e => this.setState({ primaGruppo: e.target.checked })}
              />
              <span className='checkmark' />
            </label>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p>Valore della <strong>qualità I</strong> per singola calibratura <strong>minore</strong> di </p>
            <input
              type='number'
              value={primaSingolaLimit}
              style={{ height: 30, width: 80, marginLeft: 10, marginRight: 10 }}
              onChange={e => this.setState({ primaSingolaLimit: Number(e.target.value) })}
            />
            <p>%</p>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p>Valore della <strong>qualità I</strong> rispetto alle calibrature raggruppate <strong>minore</strong> di </p>
            <input
              type='number'
              value={primaGruppoLimit}
              style={{ height: 30, width: 80, marginLeft: 10, marginRight: 10 }}
              onChange={e => this.setState({ primaGruppoLimit: Number(e.target.value) })}
            />
            <p>%</p>
          </div>

          {/* ---------------------------------------------------------------------------------------------------------------- */}

          <hr />

          <div className='checkbox-section'>
            <p>Abilita notifiche per la qualità III:</p>
            <label className='custom-checkbox'>
              <input
                type='checkbox'
                checked={enabledTerza}
                onChange={e => this.setState({ enabledTerza: e.target.checked })}
              />
              <span className='checkmark' />
            </label>
          </div>

          <div className='checkbox-section'>
            <p>Abilita notifiche per la qualità III <strong>singola</strong>:</p>
            <label className='custom-checkbox'>
              <input
                type='checkbox'
                checked={terzaSingola}
                onChange={e => this.setState({ terzaSingola: e.target.checked })}
              />
              <span className='checkmark' />
            </label>
          </div>
          <div className='checkbox-section'>
            <p>Abilita notifiche per la qualità III <strong>raggruppata</strong>:</p>
            <label className='custom-checkbox'>
              <input
                type='checkbox'
                checked={terzaGruppo}
                onChange={e => this.setState({ terzaGruppo: e.target.checked })}
              />
              <span className='checkmark' />
            </label>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p>Valore della <strong>qualità III</strong> per singola calibratura <strong>maggiore</strong> di </p>
            <input
              type='number'
              value={terzaSingolaLimit}
              style={{ height: 30, width: 80, marginLeft: 10, marginRight: 10 }}
              onChange={e => this.setState({ terzaSingolaLimit: Number(e.target.value) })}
            />
            <p>%</p>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p>Valore della <strong>qualità III</strong> rispetto alle calibrature raggruppate <strong>maggiore</strong> di </p>
            <input
              type='number'
              value={terzaGruppoLimit}
              style={{ height: 30, width: 80, marginLeft: 10, marginRight: 10 }}
              onChange={e => this.setState({ terzaGruppoLimit: Number(e.target.value) })}
            />
            <p>%</p>
          </div>

          <Button variant='success' onClick={() => this.saveAutomations()} style={{ }}>Salva</Button>
        </div>
      </div>
    )
  }
}

export default WithRouter(Automations)
