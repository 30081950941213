import React, { Component } from 'reactn'
import moment from 'moment'
import { Button, InputGroup, FormControl } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import filterFactory from 'react-bootstrap-table2-filter'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import md5 from 'md5'
import Modal from 'react-modal'
import swal from 'sweetalert'

import { Card } from './Components'
import Api from '../Services/Api'

import TableDetail from './TableDetail'
import up from '../Assets/Icons/up.png'
import down from '../Assets/Icons/down.png'
import ok from '../Assets/ok.png'
import WithRouter from './Components/WithRouter'

Modal.defaultStyles.overlay.backgroundColor = 'rgba(30, 30, 30, 0.8)'

const renderCell = (cell, type) => <p style={styles.cell}>{cell}</p>

const renderCheck = (cell) => {
  if (cell) {
    return <img alt='' src={ok} style={{ width: 30, height: 30 }} />
  }
}

class Users extends Component {
  constructor (props) {
    super(props)
    this.state = {
      users: [],
      newUser: false,
      email: '',
      name: '',
      type: '',
      soci: []
    }
  }

  async componentDidMount () {
    await this.loadData()
  }

  async loadData () {
    const res = await Api.get('/users')
    if (res.ok) {
      const _users = res.data.map(user => {
        let passwordTemp = ''
        if (user.firstLogin) {
          const hash = md5((user.email).toString())
          passwordTemp = hash.substring(hash.length - 8, hash.length)
        } else {
          passwordTemp = '******'
        }
        return {
          ...user,
          passwordTemp
        }
      })

      this.setState({ users: _users })
    } else {
      swal('Impossibile recuperare i dati degli utenti.')
    }

    const { user } = this.global
    const ret = await Api.get('/aggregate/fornitori', { idUtente: user._id, skipUserSoci: true })
    this.setState({ soci: ret.data })
  }

  async registerNewUser () {
    const { email, name, type } = this.state
    if (email === '' || name === '' || type === '...') {
      swal('Si prega di verificare i dati inseriti prima di procedere.')
    } else {
      const hash = md5((email).toString())
      const password = hash.substring(hash.length - 8, hash.length)

      const res = await Api.post('/users', {
        email,
        password,
        name,
        type,
        firstLogin: true
      })
      if (res.ok) {
        swal('Utente registrato con successo!')
        this.setState({ newUser: false, email: '', name: '', type: '' })
      } else {
        swal('Impossibile registrare l\'utente, si prega di riprovare.')
      }
    }
  }

  render () {
    const { users, newUser, email, name, type, soci } = this.state
    const { container, table, toolkit } = styles
    const { SearchBar, ClearSearchButton } = Search
    const { ExportCSVButton } = CSVExport

    const defaultSorted = [{
      dataField: 'type',
      order: 'asc'
    }]

    const expandRow = {
      renderer: row => <TableDetail data={row} soci={soci} loadData={() => this.loadData()} />,
      showExpandColumn: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <img src={up} width={20} height={20} alt='' />
            </div>
          )
        }
        return (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <img src={down} width={20} height={20} alt='' />
          </div>
        )
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <img src={up} width={20} height={20} alt='' />
            </div>
          )
        }
        return (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <img src={down} width={20} height={20} alt='' />
          </div>
        )
      }
    }

    const rowEvents = {
      onClick: (e, row, rowIndex) => {}
    }

    const rowBackground = (row, rowIndex) => {
      const style = {}
      rowIndex % 2 === 0 ? style.backgroundColor = '#cfdede' : style.backgroundColor = '#f9f9f9'
      return style
    }

    const options = {
      paginationSize: 4,
      pageStartIndex: 0,
      alwaysShowAllBtns: true,
      withFirstAndLast: true,
      firstPageText: 'Inzio',
      prePageText: 'Indietro',
      nextPageText: 'Avanti',
      lastPageText: 'Fine',
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => {
        return (<i style={{ marginLeft: 10 }}>Records da <b>{from}</b> a <b>{to}</b> di <b>{size}</b> risultati</i>)
      },
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }]
    }

    return (
      <Card style={container}>
        <Card shadow style={table}>
          <ToolkitProvider
            keyField='_id'
            data={users}
            columns={fields}
            style={{ width: '100%' }}
            rowEvents={rowEvents}
            loading
            striped
            hover
            search
            exportCSV={{
              fileName: `Inventario_${moment().format('YYYY-MM-DD')}.csv`
            }}
          >
            {
              props =>
                <Card style={{ width: '100%', padding: 20 }}>
                  <Card style={toolkit}>
                    <button className='button' onClick={() => this.props.navigate('/')}>
                      <i className='fas fa-arrow-left' />
                    </button>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row', width: '100%' }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder='Inizia a digitare per cercare nella tabella...'
                        delay={50}
                        srText=''
                        style={{ width: 400, minWidth: 200, height: 40, marginRight: 10, marginTop: 10 }}
                      />
                      <ClearSearchButton {...props.searchProps} style={{ color: 'white' }} className='reset-btn' text='Reset' />
                      <ExportCSVButton {...props.csvProps} style={{ width: 110, color: 'white', backgroundColor: 'orange' }}>Export CSV</ExportCSVButton>
                      <Button variant='success' style={{ marginLeft: 15 }} onClick={() => this.setState({ newUser: true })}>Nuovo utente</Button>
                    </div>
                  </Card>
                  <hr />
                  <BootstrapTable
                    {...props.baseProps}
                    noDataIndication='Non ci sono dati da visualizzare.'
                    filter={filterFactory()}
                    pagination={paginationFactory(options)}
                    expandRow={expandRow}
                    tabIndexCell
                    bootstrap4
                    defaultSorted={defaultSorted}
                    rowEvents={rowEvents}
                    rowStyle={rowBackground}
                  />
                </Card>
            }
          </ToolkitProvider>
        </Card>

        <Modal
          isOpen={newUser}
          style={customStyle}
          ariaHideApp={false}
          className='newUserModal'
          onRequestClose={() => this.setState({ newUser: !newUser })}
        >
          <div style={{ backgroundColor: 'white', minWidth: 400, padding: 20, borderRadius: 20 }}>
            <p style={{ fontSize: 18, fontWeight: 'bold' }}>Registrazione Nuovo Utente</p>

            <InputGroup.Text>Email / Codice Fornitore</InputGroup.Text>
            <InputGroup className='mb-3'>
              <FormControl value={email} onChange={(e) => this.setState({ email: e.target.value })} />
            </InputGroup>

            <InputGroup.Text>Nome / Ragione Sociale</InputGroup.Text>
            <InputGroup className='mb-3'>
              <FormControl value={name} onChange={(e) => this.setState({ name: e.target.value })} />
            </InputGroup>

            <InputGroup.Text>Tipo utente</InputGroup.Text>
            <select value={type} onChange={e => this.setState({ type: e.target.value })} style={{ width: '100%', height: 40, marginBottom: 15 }}>
              <option value='...'>...</option>
              <option value='admin'>Admin</option>
              <option value='user'>User</option>
            </select>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant='success' onClick={() => this.registerNewUser()} style={{ marginLeft: 15 }}>Registra utente</Button>
            </div>
          </div>
        </Modal>
      </Card>
    )
  }
}

const customStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    position: 'absolute',
    zIndex: 999999999999999,
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: 20
  },
  outline: 'none'
}

const styles = {
  column: {
    backgroundColor: 'green',
    color: 'white',
    fontSize: 16
  },
  row: {
    color: 'white',
    height: 20
  },
  cell: {
    fontSize: 15,
    wordWrap: 'break-word'
  },
  container: {
    display: 'flex'
  },
  table: {
    width: '100%'
  },
  toolkit: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  exportBtn: {
    width: 100,
    height: 40,
    backgroundColor: 'red',
    color: 'white',
    marginRight: 10,
    marginTop: 5
  }
}

const fields = [
  {
    dataField: '_id',
    text: 'id',
    headerStyle: { ...styles.column, width: '15%' },
    sort: true,
    formatter: cell => renderCell(cell),
    align: 'left',
    key: 4
  },
  {
    dataField: 'email',
    text: 'Cod. Fornitore',
    headerStyle: { ...styles.column, width: '15%' },
    sort: true,
    formatter: cell => renderCell(cell),
    align: 'left',
    key: 4
  },
  {
    dataField: 'passwordTemp',
    text: 'Password Temp',
    headerStyle: { ...styles.column, width: '5%' },
    sort: true,
    formatter: cell => renderCell(cell),
    align: 'left',
    key: 4
  },
  {
    dataField: 'name',
    text: 'Ragione Sociale / Nome',
    headerStyle: { ...styles.column, width: '45%' },
    sort: true,
    formatter: cell => renderCell(cell),
    align: 'left',
    key: 4
  },
  {
    dataField: 'type',
    text: 'Tipo',
    headerStyle: { ...styles.column, width: '10%' },
    sort: true,
    formatter: cell => renderCell(cell),
    align: 'left',
    key: 4
  },
  {
    dataField: 'firstLogin',
    text: 'Primo Login?',
    headerStyle: { ...styles.column, width: '10%' },
    sort: true,
    formatter: cell => renderCheck(cell),
    align: 'left'
  }
]

export default WithRouter(Users)
