import React, { Component, addCallback } from 'reactn'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Home, Login, Notifications, Users, Automations } from './Pages'
import Background from './Components/Background'
import { restoreState } from './Services'
import './App.css'

export default class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loggedIn: false
    }
  }

  async componentDidMount () {
    await restoreState()
    this.setState({ loggedIn: this.global.loggedIn })

    addCallback(global => {
      if (global.loggedIn === true || global.loggedIn === false) {
        this.setState({ loggedIn: global.loggedIn })
      }

      return null
    })
  }

  renderApp () {
    return (
      <Router>
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/users' element={<Users />} />
          <Route path='/notifications' element={<Notifications />} />
          <Route path='/automations' element={<Automations />} />
        </Routes>
      </Router>
    )
  }

  postLogin () {
    this.setState({ loggedIn: true })
  }

  render () {
    const { loggedIn } = this.state
    return (
      loggedIn
        ? this.renderApp()
        : <Background><Login postLogin={() => this.postLogin()} /></Background>
    )
  }
}
